"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopListsClientProxy = void 0;
const default_top_lists_client_1 = require("./default-top-lists-client");
class TopListsClientProxy {
    constructor(accessToken) {
        this.cache = {};
        this.client = new default_top_lists_client_1.DefaultTopListsClient(accessToken);
    }
    getTopArtists(timeRange, limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = `artists-${timeRange}-${limit}-${offset}`;
            if (this.cache.hasOwnProperty(cacheKey))
                return Promise.resolve(this.cache[cacheKey]);
            const result = yield this.client.getTopArtists(timeRange, limit, offset);
            this.cache[cacheKey] = result;
            return result;
        });
    }
    getTopTracks(timeRange, limit, offset) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = `tracks-${timeRange}-${limit}-${offset}`;
            if (this.cache.hasOwnProperty(cacheKey))
                return Promise.resolve(this.cache[cacheKey]);
            const result = yield this.client.getTopTracks(timeRange, limit, offset);
            this.cache[cacheKey] = result;
            return result;
        });
    }
}
exports.TopListsClientProxy = TopListsClientProxy;
