"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationData = void 0;
class PaginationData {
    get total() {
        return this._total;
    }
    set total(value) {
        if (value < 0 || this._total == value)
            return;
        this._total = value;
        this.onChanged();
    }
    get currentLimit() {
        return this._currentLimit;
    }
    set currentLimit(value) {
        if (value < 0 || this._currentLimit == value)
            return;
        this._currentLimit = Math.min(value, this._total);
        this.onChanged();
    }
    get currentOffset() {
        return this._currentOffset;
    }
    get remainingElements() {
        return Math.min(this._total - this._currentOffset - this._currentLimit);
    }
    constructor(total, currentLimit, currentOffset) {
        this.handlers = new Map();
        this._total = total;
        this._currentLimit = currentLimit;
        this._currentOffset = currentOffset;
    }
    updateOffset() {
        if (this.remainingElements > 0) {
            this._currentOffset += this._currentLimit;
            this.onChanged();
        }
    }
    reset(total, currentLimit, currentOffset) {
        this._total = total;
        this._currentLimit = currentLimit;
        this._currentOffset = currentOffset;
    }
    registerChangedHandler(key, handler) {
        this.handlers.set(key, handler);
    }
    unregisterChangedHandler(key) {
        this.handlers.delete(key);
    }
    onChanged() {
        this.handlers.forEach((handler) => handler(this));
    }
}
exports.PaginationData = PaginationData;
