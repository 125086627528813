"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopListsClientFactory = void 0;
const top_lists_client_proxy_1 = require("./top-lists-client-proxy");
class TopListsClientFactory {
    getTopListsClient(accessToken) {
        return new top_lists_client_proxy_1.TopListsClientProxy(accessToken);
    }
}
exports.TopListsClientFactory = TopListsClientFactory;
