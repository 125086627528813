"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const select_1 = require("@material/select");
const top_lists_client_factory_1 = require("./top-lists-client-factory");
const pagination_data_1 = require("./pagination-data");
let topListsClient;
const allowedTimeRanges = ['long_term', 'medium_term', 'short_term'];
let timeRange = 'long_term';
const defaultLimit = 10;
const artistsPaginationData = new pagination_data_1.PaginationData(0, defaultLimit, 0);
const tracksPaginationData = new pagination_data_1.PaginationData(0, defaultLimit, 0);
//TODO extract common types
let accessToken = null;
document.addEventListener('DOMContentLoaded', () => __awaiter(void 0, void 0, void 0, function* () {
    if (!checkAccessToken()) {
        initializeView(false);
        return;
    }
    else {
        initializeView(true);
    }
    try {
        topListsClient = new top_lists_client_factory_1.TopListsClientFactory().getTopListsClient(accessToken === null || accessToken === void 0 ? void 0 : accessToken.token);
    }
    catch (e) {
        console.error(e);
        return; // cannot continue, TODO: error message
    }
    getTimeRange();
    initMaterialComponents();
    resetTopLists();
    yield fetchAll();
}));
const checkAccessToken = () => {
    var _a;
    const accessTokenCookie = (_a = document.cookie
        .split(';')
        .map((c) => c.trim())
        .find((c) => c.startsWith('accessToken='))) === null || _a === void 0 ? void 0 : _a.split('=')[1];
    if (!accessTokenCookie)
        return false;
    accessToken = JSON.parse(decodeURIComponent(accessTokenCookie));
    if (accessToken != null && accessToken.token !== '') {
        const now = new Date().getTime();
        //check if token is still valid and refresh if not
        if (accessToken.expires > now)
            return true;
        console.log('refreshing token');
        window.location.href = '/refresh-token';
    }
    return false;
};
const initializeView = (authorized) => {
    const topListsContainer = document.getElementById('top-lists-container');
    if (topListsContainer != null)
        topListsContainer.style.display = authorized ? 'block' : 'none';
    const authorizeContainer = document.getElementById('authorize-container');
    if (authorizeContainer != null) {
        authorizeContainer.style.display = authorized ? 'none' : 'block';
    }
    //TODO: refactor out
    const loadMoreTracksButton = document.getElementById('load-more-tracks');
    const loadMoreArtistsButton = document.getElementById('load-more-artists');
    if (loadMoreTracksButton == null || loadMoreArtistsButton == null)
        return;
    tracksPaginationData.registerChangedHandler('load-more-button', (value) => {
        loadMoreTracksButton.style.display = value.remainingElements > 0 ? 'block' : 'none';
    });
    artistsPaginationData.registerChangedHandler('load-more-button', (value) => {
        loadMoreArtistsButton.style.display = value.remainingElements > 0 ? 'block' : 'none';
    });
    loadMoreTracksButton.addEventListener('click', () => {
        console.log(`load ${tracksPaginationData.currentLimit} more tracks`);
        tracksPaginationData.updateOffset();
        fetchTopTracks();
    });
    loadMoreArtistsButton.addEventListener('click', () => {
        console.log(`load ${artistsPaginationData} more artists`);
        artistsPaginationData.updateOffset();
        fetchTopArtists();
    });
};
const initMaterialComponents = () => {
    const selectElement = document.querySelector('.mdc-select');
    if (selectElement == null)
        return;
    const select = new select_1.MDCSelect(selectElement);
    select.setSelectedIndex(allowedTimeRanges.indexOf(timeRange));
    select.listen('MDCSelect:change', () => selectedTimeRangeChanged(select.value));
};
const getTimeRange = () => {
    const queryTimeRange = new URLSearchParams(window.location.search).get('time_range');
    if (queryTimeRange && allowedTimeRanges.includes(queryTimeRange))
        timeRange = queryTimeRange;
    const timeRangeString = timeRange
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ');
    document.querySelectorAll('span.time-range-string').forEach((timeRangeElement) => (timeRangeElement.textContent = timeRangeString));
};
const selectedTimeRangeChanged = (newTimeRange) => __awaiter(void 0, void 0, void 0, function* () {
    if (allowedTimeRanges.includes(newTimeRange)) {
        timeRange = newTimeRange;
        history.pushState(null, timeRange, window.location.href.split('?')[0] + '?time_range=' + timeRange);
        resetTopLists(); // make sure old data is cleared
        yield fetchAll();
        getTimeRange();
    }
});
const addArtistCell = (index, artist) => {
    var _a;
    // use smallest image at least 300x300px, default order is widest first
    const imageUrl = (_a = artist.images.reverse().find((i) => i.height >= 300)) === null || _a === void 0 ? void 0 : _a.url;
    if (!imageUrl)
        return;
    const image = document.createElement('img');
    image.src = imageUrl;
    const artistInfo = document.createElement('div');
    artistInfo.classList.add('info');
    artistInfo.innerHTML = `<h5>${index + '. ' + artist.name}</h5><h6>${artist.genres.join(', ')}</h6>`;
    const artistItem = createCell(artist, image, artistInfo);
    document.getElementById('top-artists-grid-inner').appendChild(artistItem);
};
const addTrackCell = (index, track) => {
    var _a;
    // only use 300x300px images
    const imageUrl = (_a = track.album.images.find((i) => i.height === 300)) === null || _a === void 0 ? void 0 : _a.url;
    if (!imageUrl)
        return;
    const image = document.createElement('img');
    image.src = imageUrl;
    const trackInfo = document.createElement('div');
    trackInfo.classList.add('info');
    trackInfo.innerHTML = `<h5>${index + '. ' + track.name}</h5><h6>${track.artists.map((a) => a.name).join(', ')}</h6>`;
    const trackItem = createCell(track, image, trackInfo);
    document.getElementById('top-tracks-grid-inner').appendChild(trackItem);
};
const createCell = (topListElement, ...content) => {
    const cell = document.createElement('div');
    cell.classList.add('cell', 'mdc-layout-grid__cell', 'mdc-layout-grid__cell--span-2-desktop', 'mdc-layout-grid__cell--span-4-tablet');
    const link = document.createElement('a');
    link.classList.add('spotify-link');
    link.href = topListElement.external_urls.spotify;
    link.target = '_blank';
    const innerContainer = document.createElement('div');
    innerContainer.classList.add('inner-container');
    innerContainer.append(...content);
    link.appendChild(innerContainer);
    cell.appendChild(link);
    return cell;
};
const resetTopLists = () => {
    artistsPaginationData.reset(0, defaultLimit, 0);
    tracksPaginationData.reset(0, defaultLimit, 0);
    document.querySelectorAll('.grid-content').forEach((e) => (e.innerHTML = ''));
    document.querySelectorAll('.error-message').forEach((e) => e.classList.add('hidden'));
};
const fetchAll = () => __awaiter(void 0, void 0, void 0, function* () {
    return Promise.all([fetchTopArtists(), fetchTopTracks()]);
});
const fetchTopArtists = () => __awaiter(void 0, void 0, void 0, function* () {
    const topArtists = yield topListsClient.getTopArtists(timeRange, artistsPaginationData.currentLimit, artistsPaginationData.currentOffset);
    if (topArtists == null || topArtists.total == 0) {
        showTopArtistsErrorMessage();
        return;
    }
    artistsPaginationData.total = topArtists.total;
    topArtists.items.forEach((artist, index) => addArtistCell(++index + artistsPaginationData.currentOffset, artist));
});
const fetchTopTracks = () => __awaiter(void 0, void 0, void 0, function* () {
    const topTracks = yield topListsClient.getTopTracks(timeRange, tracksPaginationData.currentLimit, tracksPaginationData.currentOffset);
    if (topTracks == null || topTracks.total == 0) {
        showTopTracksErrorMessage();
        return;
    }
    tracksPaginationData.total = topTracks.total;
    topTracks.items.forEach((track, index) => addTrackCell(++index + tracksPaginationData.currentOffset, track));
});
const showTopArtistsErrorMessage = () => {
    const element = document.querySelector('#top-artists-grid .error-message');
    if (element == null)
        return;
    element.classList.remove('hidden');
};
const showTopTracksErrorMessage = () => {
    const element = document.querySelector('#top-tracks-grid .error-message');
    if (element == null)
        return;
    element.classList.remove('hidden');
};
