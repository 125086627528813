"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTopListsClient = void 0;
class DefaultTopListsClient {
    constructor(accessToken) {
        this.topArtistsUrl = DefaultTopListsClient.baseUrl + 'artists';
        this.topTracksUrl = DefaultTopListsClient.baseUrl + 'tracks';
        this.proxyActive = false;
        this.fatalError = false;
        if (accessToken == null || accessToken == '') {
            throw new Error('accessToken is null or empty');
        }
        this.accessToken = accessToken;
    }
    getTopArtists(timeRange, limit = 10, offset = 0) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return ((_a = (yield this.wrapCall(() => this.fetchTopData(this.topArtistsUrl, timeRange, limit, offset)))) !== null && _a !== void 0 ? _a : { items: [], total: 0 });
        });
    }
    getTopTracks(timeRange, limit = 10, offset = 0) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return ((_a = (yield this.wrapCall(() => this.fetchTopData(this.topTracksUrl, timeRange, limit, offset)))) !== null && _a !== void 0 ? _a : { items: [], total: 0 });
        });
    }
    wrapCall(functionCall) {
        return __awaiter(this, void 0, void 0, function* () {
            return functionCall().catch((error) => __awaiter(this, void 0, void 0, function* () {
                console.error('Error fetching data, switching to proxy', error);
                this.switchToProxyFallback();
                return functionCall().catch((error) => {
                    console.error('Error fetching data with proxy, aborting', error);
                    this.fatalError = true;
                    return undefined;
                });
            }));
        });
    }
    switchToProxyFallback() {
        if (this.proxyActive)
            return;
        this.topArtistsUrl = DefaultTopListsClient.proxyBaseUrl + 'artists';
        this.topTracksUrl = DefaultTopListsClient.proxyBaseUrl + 'tracks';
        this.proxyActive = true;
    }
    fetchTopData(url, timeRange, limit = 10, offset = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            if (limit < 0)
                limit = 0;
            if (offset < 0)
                offset = 0;
            if (offset > 49)
                offset = 49;
            if (offset + limit > 50)
                limit = 50 - offset;
            const result = yield fetch(`${url}?limit=${limit}&offset=${offset}&time_range=${timeRange}`, {
                mode: 'cors',
                headers: { Authorization: 'Bearer ' + this.accessToken }
            });
            return yield result.json();
        });
    }
}
exports.DefaultTopListsClient = DefaultTopListsClient;
DefaultTopListsClient.baseUrl = 'https://api.spotify.com/v1/me/top/';
DefaultTopListsClient.proxyBaseUrl = '/proxy-api/me/top/';
